<template>
  <div>
    <!-- Select messages -->
    <CButton
      v-show="items.length > 0 && !showingItem"
      v-c-tooltip="'Selecionar'"
      color="light"
      class="mailbox-toolbar-select mr-2"
    >
      <CInputCheckbox
        :checked="selectAll"
        :label="' '"
        :custom="true"
        :inline="true"
        @update:checked="$emit('select-all', $event)"
      />
    </CButton>

    <!-- Message actions -->
    <CButtonGroup v-show="selectedItems.length > 0 || showingItem" class="mr-2">
      <CButton
        v-c-tooltip="'Voltar'"
        color="light"
        class="mr-4"
        @click="$emit('back')"
      >
        <i class="fas fa-arrow-left"></i>
      </CButton>
      <CButton
        v-show="showMarkAsRead"
        v-c-tooltip="'Marcar como lido'"
        color="light"
        @click="$emit('mark-as-read')"
      >
        <i class="fas fa-envelope"></i>
      </CButton>
      <CButton
        v-show="!showMarkAsRead"
        v-c-tooltip="'Marcar como não lido'"
        color="light"
        @click="$emit('mark-as-unread')"
      >
        <i class="fas fa-envelope-open"></i>
      </CButton>
      <CButton
        v-show="showBookmark"
        v-c-tooltip="'Marcar como favorito'"
        color="light"
        @click="$emit('bookmark')"
      >
        <i class="far fa-star"></i>
      </CButton>
      <CButton
        v-show="!showBookmark"
        v-c-tooltip="'Remover dos favoritos'"
        color="light"
        @click="$emit('remove-bookmark')"
      >
        <i class="fas fa-star"></i>
      </CButton>
      <CButton v-c-tooltip="'Remover'" color="light" @click="$emit('remove')">
        <i class="fas fa-trash-alt"></i>
      </CButton>
      <CButton
        v-show="showingItem"
        v-c-tooltip="'Responder e-mail'"
        color="light"
        @click="reply(showingItem.email)"
      >
        <i class="fas fa-reply"></i>
      </CButton>
    </CButtonGroup>

    <!-- Refresh messages -->
    <CButtonGroup
      v-show="selectedItems.length === 0 && !showingItem"
      class="mr-2"
    >
      <CButton
        v-c-tooltip="'Atualizar'"
        color="light"
        @click="$emit('refresh')"
      >
        <i class="far fa-sync" :class="{ refreshing: refreshing }"></i>
      </CButton>
    </CButtonGroup>

    <!-- Return to messages list -->
    <CButtonGroup v-show="showingItem" class="float-right" style="z-index: 10">
    </CButtonGroup>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    },

    selectedItems: {
      type: Array,
      default: () => {
        return []
      }
    },

    showingItem: {
      type: [Object, Boolean],
      default: false
    },

    selectAll: {
      type: Boolean,
      default: false
    },

    refreshing: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  },

  computed: {
    showMarkAsRead() {
      const hasUnreadMessage = this.selectedItems.filter((item) => {
        if (!item.read) {
          return true
        }
      })

      return hasUnreadMessage.length > 0
    },

    showBookmark() {
      const hasNoFavorite = this.selectedItems.filter((item) => {
        if (!item.favorite) {
          return true
        }
      })

      return hasNoFavorite.length > 0
    }
  },

  methods: {
    reply(email) {
      window.location.href = `mailto:${email}`
    }
  }
}
</script>

<style lang="scss"></style>
